import Navbar from './Navbar';
import Home from './HomePage/Home';
import About from './AboutPage/About'
import Explore from './ExplorePage/Explore';
import Testimonials from './TestimonialPage/Testimonials';
import Schedule from './SchedulePage/Schedule';
import Contact from './ContactPage/Contact';
import Players from './ExplorePage/Players';
import Coaches from './ExplorePage/Coaches';
import Legends from './ExplorePage/Legends';
import Errors from './Errors';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {

  return (
    <Router>
      <div className="App">
        <Navbar></Navbar>
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home></Home>
            </Route>
            <Route exact path="/about">
              <About></About>
            </Route>
            <Route exact path="/explore">
              <Explore></Explore>
            </Route>
            <Route exact path="/testimonials">
              <Testimonials></Testimonials>
            </Route>
            <Route exact path="/schedule">
              <Schedule></Schedule>
            </Route>
            <Route exact path="/contact">
              <Contact></Contact>
            </Route>
            <Route exact path="/players">
              <Players></Players>
            </Route>
            <Route exact path="/coaches">
              <Coaches></Coaches>
            </Route>
            <Route exact path="/legends">
              <Legends></Legends>
            </Route>
            <Route path="*">
              <Errors></Errors>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
