import Information from "./Information";
import Sponsors from "./Sponsors";

const About = () => {
    return ( 
        <div className="about">
            <div className="shape3"></div>
            <div className="shape4"></div>

            <h1>About Us. <img src="https://img.icons8.com/fluent-systems-regular/48/000000/about.png"/></h1>
            
            <Information></Information>
            <Sponsors></Sponsors>
        </div>
     );
}
 
export default About;