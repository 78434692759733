import { Container, Row, Col, Card, Button } from "react-bootstrap";

const Explore = () => {
    return ( 
        <div className="explore">

            <div className="shape6"></div>
            <div className="shape7"></div>

            <h1>Explore. <img src="https://img.icons8.com/ios-filled/50/000000/search--v1.png"/></h1>
            <br></br>

            <Container>
                <Row>
                    <Col>
                        <a href="/players">
                        <Card className="bg-dark text-white">
                            <Card.Img src="/player2.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="cardTitle">Players.</Card.Title>
                                <Card.Text className="cardText">
                                    Learn the latest training methods, diets and workout routines from the best current players. 
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        </a>
                    </Col>

                    <Col>
                        <a href="/coaches">
                        <Card className="bg-dark text-white">
                            <Card.Img src="/coach1.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="cardTitle">Coaches.</Card.Title>
                                <Card.Text className="cardText">
                                    Learn the latest tactics and strategies , from award and championship winning coaches.
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        </a>
                    </Col>

                    <Col>
                        <a href="/legends">
                        <Card className="bg-dark text-white">
                            <Card.Img src="/player1.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="cardTitle">Legends.</Card.Title>
                                <Card.Text className="cardText">
                                    Learn how to adapt to the ever-evolving game from legends who have experience like no other.
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Explore;