const Errors = () => {
    return ( 
        <div className="errors" style={{textAlign:"center"}}>
            <div className="shape7"></div>
            <div className="shape12"></div>
            <h1 style={{fontSize:"100px"}}>Sorry.</h1>
            <h2 style={{fontSize:"50px"}}>Seems like this page does not exist. Please enter a valid URL or go back to <a href="/" className="links">home</a>.</h2>
        </div>
    );
}
 
export default Errors;