import { Form, Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";



const Contact = () => {

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    setValidated(true);
    };

    return ( 
        <div className="contact">
            <div className="shape10"></div>
            <div className="shape11"></div>

            <h1>Contact Us. <img src="https://img.icons8.com/ios/50/000000/new-post.png"/></h1>
            <p>Please fill out the form below if you have any questions, comments, concerns or reviews.</p>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="formLabel">First Name.</Form.Label>
                    <Form.Control required className="form-control" type="text" placeholder="John" />
                    <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                    <Form.Label className="formLabel">Last Name.</Form.Label>
                    <Form.Control required className="form-control" type="text" placeholder="Doe" />
                    <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label className="formLabel">Email address.</Form.Label>
                    <Form.Control required className="form-control" type="email" placeholder="name@example.com" />
                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea4">
                    <Form.Label className="formLabel">Your Message.</Form.Label>
                    <Form.Control required className="form-control" as="textarea" rows={3} />
                    <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
                </Form.Group>
                <br></br>
                <Button type="submit" className="submitButton">Submit.</Button>
            </Form>
        </div>
    );
}
 
export default Contact;