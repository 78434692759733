import { Container, Row, Col } from "react-bootstrap";

const Information = () => {
    return ( 
        <div className="information">
            <Container>
                <Row>
                    <Col>
                        <img className="creatorImageOne" src="/creator1.png"/>
                        <img className="creatorImageTwo" src="/creator2.png"/>
                    </Col>
                    <Col className="text-center" xs={6}>
                        <div className="aboutText">
                            <p>The COVID-19 pandemic led to unprecednted times in the world of sports, in particular football. Fans have been barred from stadiums, training camps have been cancelled and local club football has been halted.</p>
                            <p>This is what inspired Ethan Huckerbee (far left) and Daniel Kovačić (immediate left) to found KickStart, an online camp to connect trainess with professionals in the sport including players, coaches and legends. In only a few months KickStart has collaborated with almost 100 professionals to connect with fans.</p>
                            <p>This site will allow you to understand in greater depth the services offered by KickStart and how YOU can become like your idols.</p>
                        </div>
                        <a href="/explore" className="buttonOne">Explore.</a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Information;