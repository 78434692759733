import { Container, Row, Col } from "react-bootstrap";

const Sponsors = () => {
    return ( 
        <div className="sponsors">
            <div className="sponsorHeader">
                <h2>Sponsors.</h2>
            
                <Container>
                    <Row>
                        <Col>
                            <a href="https://www.redbull.com/ca-en/athletes">
                                <img className="Sponsor1" src="/RedBullLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.gatorade.com/">
                                <img className="Sponsor2" src="/GatoradeLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.newbalance.ca/">
                                <img className="Sponsor3" src="/NewBalanceLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.acmilan.com/en">
                                <img className="Sponsor4" src="/AcMilanLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.bvb.de/eng/">
                                <img className="Sponsor5" src="/DortmundLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://en.psg.fr/">
                                <img className="Sponsor6" src="/ParisLogo.png"/>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://www.mancity.com/">
                                <img className="Sponsor7" src="/ManchesterLogo.png"/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        <br></br>
        </div>
    );
}
 
export default Sponsors
