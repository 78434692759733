
const Navbar = () => {
    return (
        <nav className="navbar">

            <a href="/"><img className="navlogo" src="/KickStartLogo.png"/> </a>
            <div className="links">
                <a href="/about">About Us.</a>
                <a href="/explore">Explore.</a>
                <a href="/testimonials">Testimonials.</a>
                <a href="/schedule">Schedule.</a>
                <a href="/contact">Contact.</a>
            </div>
        </nav>
    );
}
 
export default Navbar;