import { Container, Row, Col, Card } from "react-bootstrap"

const Players = () => {
    return ( 
        <div className="players">

            <div className="shape6"></div>
            <h1><a href="/explore" className="exploreTab">Explore.</a> &rarr; Players.</h1>

            <Container>
                <h2>Attackers.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/neymar.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Neymar Jr.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 29
                                    <br></br>
                                    Club: PSG 
                                    <br></br>
                                    Price: $2000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/mbappe.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Kylian Mbappe.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 22
                                    <br></br>
                                    Club: PSG 
                                    <br></br>
                                    Price: $1500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/haaland.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Erling Haaland.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 20
                                    <br></br>
                                    Club: Dortmund
                                    <br></br>
                                    Price: $1000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/gio.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Gio Reyna.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 18 
                                    <br></br>
                                    Club: Dortmund
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>

                <br></br>

                <h2>Defenders.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/tomori.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Fikayo Tomori.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 29
                                    <br></br>
                                    Club: A.C. Milan
                                    <br></br>
                                    Price: $1500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/calabria.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Davide Calabria.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 22
                                    <br></br>
                                    Club: A.C. Milan
                                    <br></br>
                                    Price: $1000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/walker.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Kyle Walker.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 20
                                    <br></br>
                                    Club: Manchester City
                                    <br></br>
                                    Price: $750
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/kimpembe.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Pres Kimpembe.</Card.Title>
                                <Card.Text className="playerText">
                                    Age: 18 
                                    <br></br>
                                    Club: PSG
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Players;