import { Container, Row, Col, Card } from "react-bootstrap";

const Legends = () => {
    return ( 
        <div className="legends">
            <div className="shape6"></div>
            <div className="shape7"></div>
            <h1><a href="/explore" className="exploreTab">Explore.</a> &rarr; Legends.</h1>

            <Container>
                <h2>Italy.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend1.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Franceso Totti.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Roma
                                    <br></br>
                                    Years Active: 1992-2017
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend2.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Paolo Maldini.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: A.C. Milan
                                    <br></br>
                                    Years Active: 1984-2009
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend3.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Daniel De Rossi.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Roma
                                    <br></br>
                                    Years Active: 2000-2019
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend4.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Genaro Gattuso.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: A.C. Milan 
                                    <br></br>
                                    Years Active: 1999-2012
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>

                <h2>England.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend5.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">P Schmeichel.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Manchester United
                                    <br></br>
                                    Years Active: 1991-1999
                                    <br></br>
                                    Price: $500
                                </Card.Text>    
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend6.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Rio Ferdinand.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Manchester United
                                    <br></br>
                                    Years Active: 2002-2014
                                    <br></br>
                                    Price: $500
                                </Card.Text>   
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend7.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Wayne Rooney.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Manchester United
                                    <br></br>
                                    Years Active: 2004-2017
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/Legend8.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Yaya Toure.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Manchester City
                                    <br></br>
                                    Years Active: 2010-2018
                                    <br></br>
                                    Price: $500
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Legends
