import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";


const Schedule = () => {

    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    setValidated(true);
    };

    return (
        <div className="schedule">
        <div className="shape10"></div>
        <div class="shape8"></div>

        <h1>Schedule. <img src="https://img.icons8.com/ios/50/000000/overtime.png"/></h1>
        <p>Schedule a training session with one of our partners. Due to their tight schedule, we will attempt to schedule a booking with your preferred choice, but this may not be possible. We will send you a private ivitiation to your email when confirmed.</p>
        
        <div className="signIn">
            <Form className="error" noValidate validated={validated} onSubmit={handleSubmit}>
                <h2>Personal Information. <img src="https://img.icons8.com/material-outlined/24/000000/information.png"/></h2>
                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="formLabel">Name</Form.Label>
                        <Form.Control required className="form-control" type="text" placeholder="John Doe" />
                        <Form.Control.Feedback type="invalid">Please enter a valid name.</Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label required className="formLabel">Phone Number</Form.Label>
                        <Form.Control required className="form-control" type="text" placeholder="000-000-0000" />
                        <Form.Control.Feedback type="invalid">Please enter a valid phone number.</Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                        <Form.Label className="formLabel">Email address</Form.Label>
                        <Form.Control required className="form-control" type="email" placeholder="name@example.com" />
                        <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                        <Form.Text className="text-muted">We'll send you a personal link to a meeting with the player based on their schedule.</Form.Text>
                    </Form.Group>
                </Row>

                <h2>Session Details. <img src="https://img.icons8.com/material-outlined/24/000000/calendar--v1.png"/></h2>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                        <Form.Label className="formLabel">Select a Trainer.</Form.Label>
                            {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                                <Form.Check
                                    inline
                                    label="Player"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                    required
                                />
                                <Form.Check
                                    inline
                                    label="Coach"
                                    name="group1"
                                    type={type}
                                     id={`inline-${type}-2`}
                                    required
                                />
                                 <Form.Check
                                    inline
                                    label="Legend"
                                    name="group1"
                                     type={type}
                                     id={`inline-${type}-3`}
                                    required
                                 />
                            </div>
                        ))}
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                        <Form.Label className="formLabel">Name of Preferred Trainer:</Form.Label>
                        <Form.Control required className="form-control" type="text" placeholder="John Doe" />
                        <Form.Control.Feedback type="invalid">Please enter a valid trainer name.</Form.Control.Feedback>
                        <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                        <Form.Text className="text-muted">Due to their tight schedule, we will attempt to schedule a booking with your preferred choice, but this may not be possible.</Form.Text>
                    </Form.Group>
                </Row>

                <h2>Payment. <img src="https://img.icons8.com/material-outlined/24/000000/card-in-use.png"/></h2>

                
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
                    <Form.Label className="formLabel">Select a Payment Method.</Form.Label>
                    {['radio'].map((type) => (
                    <div key={`inline-${type}`} className="mb-4">
                        <Form.Check
                            inline
                            label="Mastercard"
                            name="group2"
                            type={type}
                            id={`inline-${type}-4`}
                            required
                        />
                        <Form.Check
                            inline
                            label="Visa"
                            name="group2"
                            type={type}
                            id={`inline-${type}-5`}
                            required
                        />
                        <Form.Check
                            inline
                            label="American Express"
                            name="group2"
                            type={type}
                            id={`inline-${type}-6`}
                            required
                        />
                    </div>
                    ))}
                </Form.Group>

                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
                            <Form.Label className="formLabel">Name on Card:</Form.Label>
                            <Form.Control required className="form-control" type="text" placeholder="John Doe" />
                            <Form.Control.Feedback type="invalid">Please enter a valid name.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
                            <Form.Label className="formLabel">Credit Card Number:</Form.Label>
                            <Form.Control required className="form-control" type="text" placeholder="1234 5678 1234 5678" />
                            <Form.Control.Feedback type="invalid">Please enter a valid credit card number.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
                            <Form.Label className="formLabel">CVV:</Form.Label>
                            <Form.Control required className="form-control" type="text" placeholder="000" />
                            <Form.Control.Feedback type="invalid">Please enter a valid CVV.</Form.Control.Feedback>
                            <Form.Control.Feedback type="valid">Looks good!</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <br></br>

                <Button type="submit" className="submitButton">Submit.</Button>

            </Form>
        </div>
        </div>
    );
}
 
export default Schedule;