import { Container, Row, Col, OverlayTrigger } from "react-bootstrap";

const Home = () => {
    return ( 
        <div className="home">
            <div className="parent">
                <div className="hider">
                    <div className="shape1"></div>
                </div>
            </div>
            <div className="parent">
                <div className="hider">
                    <div className="shape2"></div>
                </div>
            </div>

            <Container>
                <Row>
                    <Col>
                        <img className="heroimage" src="/undraw_goal_0v5v.svg"/>
                    </Col>
                    <Col className="text-center">
                        <div className="heroText">
                            <h1 style= {{fontSize: "48px", textAlign: "left"}}>Talk with your feet. Play with your heart.</h1>
                            <br></br>
                            <p style= {{fontSize: "19px", textAlign: "left", fontWeight: "900"}}>Welcome to a one-of-a-kind online football camp where you have access to professionals in the industry to improve your skills! Learn more about us by clicking the button below or by accessing the navbar. </p>
                            <br></br>     
                            <a href="/about" className="buttonOne">Learn More</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Home;