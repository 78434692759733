import { Container, Row, Col, Card } from "react-bootstrap";

const Testimonials = () => {
    return ( 

        <div className="testimonials">

            <div class="shape7"></div>
            <div class="shape8"></div>
            <div class="shape9"></div>

            <h1>Testimonials. <img src="https://img.icons8.com/ios/50/000000/quote.png"/></h1>
            <br></br>

            <Container>
                <Row>
                    <Col>
                        <img src="/studentOne.jpg" width="300px" className="testyImages"></img>
                    </Col>
                    <Col xs={8}>
                        <p className="testies">"KickStart is the place to go if you are a serious soccer player and if you love the game. The camp can be exhausting but by the end of it, you feel as if you were a step away from playing with Messi and Iniesta."</p>
                        <p className="names">- Hunda Weller</p>
                    </Col>
                </Row>

                <br></br>
                <br></br>

                <Row>
                    <Col xs={8}>
                        <p className="testies">"The COVID-19 pandemic cut my season short last year. I was afraid of losing my skills, but thanks to KickStart I was able to not only retain my skills but also improve them through the help of professionals."</p>
                        <p className="names">- Ben Wyatt</p>
                    </Col>

                    <Col>
                        <img src="/studentTwo.jpg" width="300px" className="testyImages"></img>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Testimonials;