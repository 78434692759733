import { Container, Row, Col, Card } from "react-bootstrap";

const Coaches = () => {
    return ( 
        <div className="coaches">

            <div className="shape4"></div>
            <div className="shape8"></div>
            <h1><a href="/explore" className="exploreTab">Explore.</a> &rarr; Coaches.</h1>
            
            <Container>
                <h2>Italy.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/pioli.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Stefano Pioli.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: A.C. Milan
                                    <br></br>
                                    Strength: Rebuild
                                    <br></br>
                                    Price: $5000
                                </Card.Text>  
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/sarri.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Maurizio Sarri.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Lazio 
                                    <br></br>
                                    Strength: Passing plays 
                                    <br></br>
                                    Price: $4000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/allegri.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Max Allegri.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Juventus
                                    <br></br>
                                    Strengths: Formation Usage
                                    <br></br>
                                    Price: $3000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/mourinho.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Jose Mourinho.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Roma
                                    <br></br>
                                    Strengths: Unity
                                    <br></br>
                                    Price: $2000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>

                <h2>England.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/arteta.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Mikel Arteta.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Arsenal
                                    <br></br>
                                    Strengths: idk tbf.
                                    <br></br>
                                    Price: $5000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/rafa.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Rafa Benitez.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Everton 
                                    <br></br>
                                    Strength: Counter-Attacks
                                    <br></br>
                                    Price: $4000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/moyes.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">David Moyes.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: West Ham
                                    <br></br>
                                    Strength: Reactive Tactics 
                                    <br></br>
                                    Price: $3000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/potter.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Graham Potter.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Brighton 
                                    <br></br>
                                    Strength: Switches of Play
                                    <br></br>
                                    Price: $2000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>

                <h2>Other.</h2>
                <Row>
                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/march.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Jesse Marsch.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: RB Leipzig
                                    <br></br>
                                    Club: Formation Shape
                                    <br></br>
                                    Price: $5000
                                </Card.Text>   
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/jorge.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Jorge Sampaoli.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Marseille 
                                    <br></br>
                                    Strength: Ball Retention 
                                    <br></br>
                                    Price: $4000
                                </Card.Text>    
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/poch.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">M Pochettino.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: PSG
                                    <br></br>
                                    Strength: Advance Positions
                                    <br></br>
                                    Price: $3000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="bg-dark text-white">
                            <Card.Img src="/terzic.jpg" alt="Card image" />
                            <Card.ImgOverlay>
                                <Card.Title className="playerTitle">Edin Terzic.</Card.Title>
                                <Card.Text className="playerText">
                                    Club: Dortmund
                                    <br></br>
                                    Strength: Advanced Passing
                                    <br></br>
                                    Price: $2000
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
 
export default Coaches;